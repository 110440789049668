/*Don't forget to add Font Awesome CSS : "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css"*/
input[type="text"] {
  width: 75%;
  border: 2px solid #aaa;
  border-radius: 4px;
  margin: 8px 0;
  outline: none;
  padding: 8px;
  box-sizing: border-box;
  transition: 0.3s;
}

input[type="short-text"] {
  width: 50%;
  border: 2px solid #aaa;
  border-radius: 4px;
  margin: 8px 0;
  outline: none;
  padding: 8px;
  box-sizing: border-box;
  transition: 0.3s;
}

input[type="char-text"] {
  width: 5%;
  border: 2px solid #aaa;
  border-radius: 4px;
  margin: 8px 0;
  outline: none;
  padding: 8px;
  box-sizing: border-box;
  transition: 0.3s;
}

input[type="text"]:focus {
  border-color: dodgerBlue;
  box-shadow: 0 0 8px 0 dodgerBlue;
}

input[type="short-text"]:focus {
  border-color: dodgerBlue;
  box-shadow: 0 0 8px 0 dodgerBlue;
}

input[type="char-text"]:focus {
  border-color: dodgerBlue;
  box-shadow: 0 0 8px 0 dodgerBlue;
}

.inputWithIcon input[type="text"] {
  padding-left: 40px;
}

.inputWithIcon {
  position: relative;
}

.inputWithIcon i {
  position: absolute;
  left: 0;
  top: 8px;
  padding: 9px 8px;
  color: #aaa;
  transition: 0.3s;
}

.inputWithIcon input[type="text"]:focus + i {
  color: dodgerBlue;
}

.inputWithIcon.inputIconBg i {
  background-color: #aaa;
  color: #fff;
  padding: 9px 4px;
  border-radius: 4px 0 0 4px;
}

.inputWithIcon.inputIconBg input[type="text"]:focus + i {
  color: #fff;
  background-color: dodgerBlue;
}

.whereto-frame {
  border: 1px solid rebeccapurple;
  padding: 10px;
  width: 200px;
}


.optionLocationYesGeneral {
  border: 1px solid rebeccapurple;
  padding: 10px;
}

.optionLocationYesSame {
  border: 1px solid plum;
  padding: 10px;
}

.optionLocationYesDifferent {
  border: 1px solid pink;
  padding: 10px;
}

.optionLocationYesBroken {
  border: 1px solid burlywood;
  padding: 10px;
}

.optionLocationNo {
  border: 1px solid orange;
  padding: 10px;
}

.option1 {
  border: 1px solid #b9d7e6;
  padding: 10px;
}

.option2 {
  border: 1px solid #f2d0af;
  padding: 10px;
}

.option3 {
  border: 1px solid #edbdbd;
  padding: 10px;
}

.entry-whereto {
  width: 650px;
}

.entry-whereto-elements {
  display:flex;
}

.whereto-next {
  width: 200px;
}

.whereto-availability {
  width: 290px;
}



.entry-transition-input {
    margin-left: 20px;
  }

  .entry-reaction-input {
    margin-left: 20px;
  }

  .btn-done {
    margin-right: 20px;
  }

  .btn-cancel {
    margin-left: 20px;
  }

  .btn-general {
    margin-left: 20px;
  }

  .select1 {
    margin-right: 20px;
  }

  .select2 {
    margin-left: 20px;
  }