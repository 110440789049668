.entry-modal {
    height: 500px;   
  overflow-y : scroll;
    position: fixed;
    z-index: 500;
    background-color: white;
    width: 90%;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 1px black;
    padding: 16px;
    left: 15%;
    top: 10%;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
}

@media (min-width: 600px) {
    .entry-modal {
        width: 800px;
        left: calc(50% - 250px);
    }
}