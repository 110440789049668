
  
  .baecard {
    margin: 30px auto;
    width: 170px;
    height: 170px;
    border-radius: 150px;
  box-shadow: 5px 5px 30px 7px rgba(0,0,0,0.25), -5px -5px 30px 7px rgba(0,0,0,0.22);
    cursor: pointer;
    transition: 0.4s;
  }

  .baecard-grey {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    margin: 30px auto;
    width: 170px;
    height: 170px;
    border-radius: 150px;
  box-shadow: 5px 5px 30px 7px rgba(0,0,0,0.25), -5px -5px 30px 7px rgba(0,0,0,0.22);
    cursor: pointer;
    transition: 0.4s;
  }
  
  .baecard .baecard_image {
    width: inherit;
    height: inherit;
    border-radius: 40px;
  }
  
  .baecard .baecard_image img {
    width: inherit;
    height: inherit;
    border-radius: 150px;
    object-fit: cover;
  }
  
  .baecard .baecard_title {
    text-align: center;
    border-radius: 0px 0px 40px 40px;
    font-family: sans-serif;
    font-weight: bold;
    font-size: 30px;
    margin-top: -80px;
    height: 40px;
  }
  
  .baecard:hover {
    transform: scale(1.1, 1.1);
    box-shadow: 5px 5px 30px 15px rgba(0,0,0,0.25), 
      -5px -5px 30px 15px rgba(0,0,0,0.22);
  }
  
  .baecard-grey .baecard_image {
    width: inherit;
    height: inherit;
    border-radius: 40px;
  }
  
  .baecard-grey .baecard_image img {
    width: inherit;
    height: inherit;
    border-radius: 150px;
    object-fit: cover;
  }
  
  .baecard-grey .baecard_title {
    text-align: center;
    border-radius: 0px 0px 40px 40px;
    font-family: sans-serif;
    font-weight: bold;
    font-size: 30px;
    margin-top: -80px;
    height: 40px;
  }
  
  .baecard-grey:hover {
    transform: scale(1.1, 1.1);
    box-shadow: 5px 5px 30px 15px rgba(0,0,0,0.25), 
      -5px -5px 30px 15px rgba(0,0,0,0.22);
  }

  .baetitle-white {
    color: white;
  }

  .baetitle-white-card {
    color: black;
    font-family: sans-serif;
    font-weight: bold;
    font-size: 40px;
    text-align: center;
  }
  
  .baetitle-black {
    color: black;
  }
  
  @media all and (max-width: 500px) {
    .card-list {
      /* On small screens, we are no longer using row direction but column */
      flex-direction: column;
    }
  }
  
  
  /*
  .card {
    margin: 30px auto;
    width: 300px;
    height: 300px;
    border-radius: 40px;
    background-image: url('https://i.redd.it/b3esnz5ra34y.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-repeat: no-repeat;
  box-shadow: 5px 5px 30px 7px rgba(0,0,0,0.25), -5px -5px 30px 7px rgba(0,0,0,0.22);
    transition: 0.4s;
  }
  */