@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:100,300,400,700');
@import url('https://fonts.googleapis.com/css?family=Raleway:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');

*{
  box-sizing: border-box;
}

bae-image-body, bae-image-html {
   font-family: 'Roboto Slab', serif;
    margin: 0;
  width: 100%;
height: 100%;
    padding: 0;
}

.images-container {
    display: inline-block;
  }

bae-image-body {
  background-color: #D2DBDD;
  display: flex;
}


/* .bae-image-card--1 .bae-image-card__img, .bae-image-card--1 .bae-image-card__img--hover {
    background-image: url('https://images.pexels.com/photos/45202/brownie-dessert-cake-sweet-45202.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260');
}

.bae-image-card--2 .card__img, .bae-image-card--2 .bae-image-card__img--hover {
    background-image: url('https://images.pexels.com/photos/307008/pexels-photo-307008.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260');
} */

.bae-image-card__like {
    width: 18px;
}

.bae-image-card__clock {
    width: 15px;
  vertical-align: middle;
    fill: #AD7D52;
}
.bae-image-card__time {
    font-size: 24px;
    color: #AD7D52;
    vertical-align: middle;
    margin-left: 5px;
}

.bae-image-card__clock-info {
    float: right;
}

.bae-image-card__img {
  visibility: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 235px;
  border-top-left-radius: 12px;
border-top-right-radius: 12px;
  
}

.bae-image-card__info-hover {
    position: absolute;
    padding: 16px;
  width: 100%;
  opacity: 0;
  top: 0;
}

.bae-image-card__img--hover {
  transition: 0.2s all ease-out;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
  position: absolute;
    height: 235px;
  border-top-left-radius: 12px;
border-top-right-radius: 12px;
top: 0;
  
}
.bae-image-card {
    margin: 25px 25px 25px 25px;
  transition: all .4s cubic-bezier(0.175, 0.885, 0, 1);
  background-color: #fff;
    width: 300px;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 13px 10px -7px rgba(0, 0, 0,0.1);
}
.bae-image-card:hover {
  box-shadow: 0px 30px 18px -8px rgba(0, 0, 0,0.1);
    transform: scale(1.10, 1.10);
}

.bae-image-card__info {
  background-color: #fff;
  border-bottom-left-radius: 12px;
border-bottom-right-radius: 12px;
   margin: 8px 8px 8px 8px;
}

.bae-image-card__category {
    font-family: 'Raleway', sans-serif;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 2px;
    font-weight: 500;
  color: #868686;
}

.bae-image-card__title {
    margin-top: 5px;
    margin-bottom: 10px;
    font-family: 'Roboto Slab', serif;
}


.bae-image-card__by {
    font-size: 12px;
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
}

.bae-image-card__author {
    font-weight: 600;
    text-decoration: none;
    color: #AD7D52;
}

.bae-image-card:hover .bae-image-card__img--hover {
    height: 100%;
    opacity: 0.3;
}

.bae-image-card:hover .bae-image-card__info {
    background-color: transparent;
    position: relative;
}

.bae-image-card:hover .bae-image-card__info-hover {
    opacity: 1;
}
