.generalBorder {
    border: 1px solid slateblue;
    padding: 10px;
    margin: 10px;
    width: fit-content; 
  height: fit-content;
  }

  .imageBorder {
    border: 1px solid black;
    padding: 10px;
    margin: 10px;
  }

  .image-border-small {
    border: 1px solid black;
    padding: 3px;
    margin: 3px;
  }

  .imageBorderNoMargin {
    border: 1px solid black;
    padding: 10px;
  }