.cards-list {
    z-index: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .cool-list {
    z-index: 0;
    width: 100%;
    display: inline-block;
    justify-content: space-around;
    flex-wrap: wrap;
  }
