
.graph {
  width: 100%;
  height: 1000px;
}


.outer {
  display: table;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.middle {
  display: table-cell;
  vertical-align: middle;
}

.align-right-item {
  float: right;
  margin-right: 10px;
  margin-top: 20px;
}

.align-left-item-transition {
  border-radius: 20px 0px 0px 20px;
  float: left;
  width: 65px;
  padding: 5px;
  background-color: hotpink;
  height: 100%;
  color: white;
  text-align: center;
  padding-top: 23px;
}

.align-left-item-transition-inner {
  font-size: 30px;
}

.align-left-item-reaction {
  border-radius: 20px 0px 0px 20px;
  float: left;
  width: 65px;
  padding: 5px;
  background-color: #d870d8;
  height: 100%;
  color: white;
  text-align: center;
  padding-top: 23px;
}

.align-left-item-reaction-inner {
  font-size: 30px;
}

.chapter-title {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 40px;
}

.chapter-subtitle {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
}

.align-left-item-interaction {
  border-radius: 20px 0px 0px 20px;
  float: left;
  width: 65px;
  padding: 5px;
  background-color: #00c6a5;
  height: 100%;
  color: white;
  text-align: center;
  padding-top: 23px;
}

.align-left-item-interaction-inner {
  font-size: 30px;
}

.align-left-item-option {
  border-radius: 20px 0px 0px 20px;
  float: left;
  width: 65px;
  padding: 5px;
  background-color: #40cdf4;
  height: 100%;
  color: white;
  text-align: center;
  padding-top: 23px;
}

.align-left-item-option-inner {
  font-size: 30px;
}

.align-left-item-away {
  border-radius: 20px 0px 0px 20px;
  float: left;
  width: 65px;
  padding: 5px;
  background-color: #ffb62b;
  height: 100%;
  color: white;
  text-align: center;
  padding-top: 23px;
}

.align-left-item-away-inner {
  font-size: 30px;
}

.align-left-item-offline {
  border-radius: 20px 0px 0px 20px;
  float: left;
  width: 65px;
  padding: 5px;
  background-color: #ff665a;
  height: 100%;
  color: white;
  text-align: center;
  padding-top: 23px;
}

.align-left-item-offline-inner {
  font-size: 30px;
}

.flow-transition {
  width: 300px;
  height: 100px;
  border: black;
  border-width: 1px;
  border-style: solid;
  background-color: #FBF8E6;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.flow-box {
  border-radius: 20px;
  width: 400px;
  height: 100px;
  border: black;
  border-width: 1px;
  border-style: solid;
  background-color: #FBF8E6;
  margin-left: auto;
  margin-right: auto;
}

.flow-transition-title {
  color: hotpink;
  font-size: 20px;
}

.flow-reaction-title {
  color: #d870d8;
  font-size: 20px;
}

.flow-interaction-title {
  color: #00c6a5;
  font-size: 20px;
}

.flow-option-title {
  color: #40cdf4;
  font-size: 20px;
}

.flow-offline-title {
  color: #ff665a;
  font-size: 20px;
}

.flow-away-title {
  color: #ffb62b;
  font-size: 20px;
}

.flow-box-inner {
  text-align: left;
  padding-left: 90px;
  padding-top: 10px;
}



.flow-reaction {
  border: black;
  border-width: 1px;
  border-style: solid;
  background-color: #F8F8F8;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.flow-text {
  border: black;
  border-width: 1px;
  border-style: solid;
  background-color: yellowgreen;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.flow-empty-text {
  border: black;
  border-width: 1px;
  border-style: solid;
  background-color: gainsboro;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
